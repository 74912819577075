<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        @click.stop="finishOpenClick"
        :loading="finishing"
      >Finish Round ?</v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Finish {{round.name}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          @click.stop="dialog = false"
          fab small
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="finishing"></loading-bar>
      <v-card-text>
        In order to assign finishes to teams not advancing from this round, please select from the options below.
        <v-checkbox
          hide-details
          v-if="otherRounds.length === 0"
          :label="`Finish all teams as is, I am not doing playoffs`"
          v-model="fromPool"
          color="color3"
        ></v-checkbox>
        <v-checkbox
          hide-details
          v-if="otherRounds.length === 1"
          :label="`Finish all teams that did not make in to ${otherRounds[0].name} `"
          v-model="selectedRounds" :value="otherRounds[0].id"
          color="color3"
        ></v-checkbox>
        <v-checkbox
          hide-details
          v-else
          v-for="r in otherRounds" :key="r.id"
          :label="`Do not finish team that make in to ${r.name} `"
          v-model="selectedRounds" :value="r.id"
          color="color3"
        ></v-checkbox>
        <div class="text-center">
          <v-btn
            color="color3 color3Text--text"
            @click.stop="finishRound"
            :loading="finishing"
            :disabled="!(selectedRounds.length || fromPool)"
          >Finish {{round.name}}</v-btn>
        </div>
        <div class="text-divider my-4"><span>Or</span></div>
        <div class="text-center">
          <div>If you have not created all the brackets yet</div>
          <v-btn
            color="color3 color3Text--text"
            @click.stop="dialog = false"
            :loading="finishing"
          >Finish Later</v-btn>
        </div>
        <div class="text-divider my-4"><span>Or</span></div>
        <div class="text-center">
          <v-btn
            color="color3 color3Text--text"
            @click.stop="allAdvancedClick"
            :loading="finishing"
          >All teams Advance</v-btn>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center">
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import { mapGetters } from 'vuex'
import flatten from '@/helpers/ArrayFlatten'

export default {
  mixins: [RouteMixin],
  data () {
    return {
      dialog: false,
      finishing: false,
      noElim: false,
      selectedRounds: [],
      fromPool: false
    }
  },
  computed: {
    ...mapGetters(['getDivision', 'admin', 'view']),
    xs () {
      return this.$vuetify.breakpoint.xsOnly
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    round () {
      if (!this.division) return null
      return this.division.days.find(f => f.id === this.dayId)
    },
    otherRounds () {
      if (!this.division) return null
      return this.division.days.filter(f => f.id !== this.dayId)
    },
    pools () {
      return this.round ? flatten(this.round.flights.map(flight => flight.pools)) : []
    },
    dto () {
      return {
        roundId: this.round.id,
        eliminateNone: this.noElim,
        eliminateExcept: this.selectedRounds,
        finishFromPool: this.fromPool
      }
    }
  },
  methods: {
    finishOpenClick () {
      this.finishing = false
      this.noElim = false
      this.selectedRounds = []
      if (this.otherRounds.length === 1) {
        this.selectedRounds = [this.otherRounds[0].id]
      } else {
        this.otherRounds.forEach(r => {
          r.published && this.selectedRounds.push(r.id)
        })
      }
      this.dialog = true
    },
    allAdvancedClick () {
      this.noElim = true
      this.finishRound()
    },
    finishFromPool () {
      this.fromPool = true
      this.finishRound()
    },
    finishRound () {
      this.finishing = true
      this.$VBL.post.finishRound(this.dto)
        .then(r => {
          this.dialog = false
        })
        .catch(e => { console.log(e.response) })
        .finally(() => {
          this.finishing = false
        })
    }
  }
}
</script>

<style scoped>
  .text-divider {
    border-bottom: 1px solid grey;
    line-height: 0.1em;
    text-align: center;
  }
  .text-divider span {
    padding: 0 10px;
    background: #fff
  }

</style>
